<template>
  <div v-if="isShow">
    <div class="body">
      <form>
        <div id="login">
          <div class="logo">
            <img src="../assets/images/logo.png">
          </div>
          <p>&nbsp;</p>
          <div class="input">
            <el-input type="text" v-model="login.username" @keyup.enter.native="putLogin" prefix-icon="el-icon-user"
                      placeholder="请输入账号"></el-input>
          </div>
          <div class="input">
            <el-input type="password" v-model="login.password" @keyup.enter.native="putLogin" prefix-icon="el-icon-lock"
                      placeholder="请输入密码"></el-input>
          </div>
          <div id="btn">
            <span class="btn_dl" @click="putLogin">登录</span>
            <span class="btn_cz" @click="resetInput">重置</span>
          </div>
        </div>
      </form>
    </div>
    <div class="icp">©2020-2030 版权所有 蓝电 <a href="https://beian.miit.gov.cn" target="_blank">京ICP备xxx号-1</a></div>
  </div>
</template>

<script>
  import {setToken} from '@/utils/auth'

  export default {
    components: {},
    data() {
      return {
        source_domain: this.config.SOURCE_DOMAIN,
        login: {
          username: '',
          password: ''
        },
        isShow: false
      };
    },
    created() {
      if(this.$route.query.token) {
        setToken(this.$route.query.token)
        this.$router.push({name: 'person_index'})
        return false
      }
      var _this = this
      this.getUserInfo()
      setTimeout(function () {
        _this.isShow = true
      }, 300)
    },
    mounted() {
    },
    computed: {},
    methods: {
      getUserInfo() {
        var that = this
        this.$api.merchant.myIndex(function (res) {
          console.log(res)
          if (res.errcode == 0) {
            that.$router.push({name: 'person_index'})
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      resetInput() {
        var that = this
        that.login.username = ''
        that.login.password = ''
      },
      putLogin() {
        var that = this
        if (that.login.username == '') {
          that.tips('用户名不能为空！')
          return ''
        }
        if (that.login.password == '') {
          that.tips('密码不能为空！')
          return ''
        }
        that.showLoading()
        var param = that.login
        this.$api.merchant.authLogin(param, function (res) {
          that.hideLoading()
          if (res.errcode == 0) {
            setToken(res.data.access_token)
            that.success('登录成功')
            that.$router.push({name: res.data.url})
          } else {
            that.fail(res.errmsg)
          }
        })
      }
    }
  };
</script>
<style scoped>
  .body {
    background: url("../assets/images/bj.jpg") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #login {
    width: 760px;
    height: 503px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(44, 103, 144, 0.5);
    box-shadow: 10px 17px 20px 0px rgba(44, 103, 144, 0.35);
    border-radius: 20px;
    margin: auto;
  }

  .input {
    margin-left: 180px;
    margin-top: 20px;
    width: 400px;
  }

  .logo {
    text-align: center;
    margin-top: 90px;
  }

  .tip {
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2C6790;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  #btn {
    padding-top: 25px;
  }

  .btn_dl {
    display: inline-block;
    text-align: center;
    width: 120px;
    height: 50px;
    line-height: 50px;
    background: #FF6D2D;
    box-shadow: 1px 2px 2px 0px rgba(44, 103, 144, 0.15);
    border-radius: 10px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 30px;
    margin-left: 230px;
    margin-right: 50px;
    cursor: pointer;
  }

  .btn_cz {
    display: inline-block;
    text-align: center;
    width: 120px;
    height: 50px;
    line-height: 50px;
    background: #FFFFFF;
    border: 1px solid #D5D7DC;
    box-shadow: 1px 2px 2px 0px rgba(44, 103, 144, 0.15);
    border-radius: 10px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }

  .icp {
    width: 100%;
    position: absolute;
    bottom: 50px;
    text-align: center;
  }
</style>
